<template>
  <div>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <h1><b-icon icon="border-width" /> Módulos de</h1>
        </div>
        <div class="card-body">
          <div class="container">
            <button
              @click="addProgram()"
              style="background-color: #1d425e; color: white"
              class="btn mb-2"
            >
              Agregar
            </button>
          </div>
          <div class="container col-12">
            <div>
              <b-table
                striped
                responsive
                stacked="lg"
                :items="desserts"
                :fields="fields"
              >
                <template #cell(state)="row">
                  <b-badge pill :variant="returnColor(row.item.state)">{{
                    returnData(row.item.state)
                  }}</b-badge>
                </template>
                <template #cell(courses)="row">
                  <b-icon
                    type="button"
                    @click="toCourses(row.id)"
                    icon="card-checklist"
                  />
                </template>
                <template #cell(actions)="row">
                  <b-icon-pencil-square
                    type="button"
                    size="sm"
                    class="mx-1"
                    @click="dateItem(row.item)"
                  ></b-icon-pencil-square>

                  <b-icon-trash
                    type="button"
                    size="sm"
                    class="mx-1"
                    @click="modalDelete(row.item)"
                  ></b-icon-trash>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal
        scrollable
        centered
        v-model="show"
        title="Usuario"
      >
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <b-alert
              :show="dismissCountDown"
              variant="danger"
              @dismiss-count-down="countDownChanged"
            >
              <strong>Error. {{ error }},</strong> ocultando en
              {{ dismissCountDown }} segundos...
            </b-alert>
            <div class="form-group">
              <label for="exampleInputEmail1">Nombre</label>
              <input
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Ingrese su nombre"
                v-model="data.name"
                required
              />
            </div>
            <div class="form-group my-3">
              <label for="exampleInputPassword1">Apellido Paterno</label>
              <input
                type="text"
                class="form-control"
                v-model="data.lastnameFather"
                placeholder="Ingrese su apellido"
                required
              />
            </div>
            <div class="form-group my-3">
              <label for="exampleInputPassword1">Apellido Materno</label>
              <input
                type="text"
                class="form-control"
                v-model="data.lastnameMother"
                placeholder="Ingrese su apellido"
                required
              />
            </div>
            <div class="form-group my-1">
              <label for="exampleInputPassword1">Fecha nacimiento</label>
              <input
                type="date"
                class="form-control"
                v-model="data.dateBirth"
                placeholder="Ingrese fecha nacimiento"
                required
              />
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              v-if="editAdd != false"
              type="button"
              @click="saveEditItem(data)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Editar
            </button>
            <button
              v-else
              type="button"
              @click="addProgram"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Agregar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
    <!--Mensaje alerta eliminacion-->

    <div>
      <b-modal centered v-model="showDelete" title="Mensaje">
        <b-container fluid>
          <!--Contenido modal-->
          <h5 class="text-center">
            ¿Seguro que desea eliminar a <b>{{ data.username }}</b
            >?
          </h5>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="deleteItem(dataDelete)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Aceptar
            </button>
            <button
              type="button"
              @click="exitDelete()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { BIcon, BIconArrowUp, BIconArrowDown } from "bootstrap-vue";
export default {
  components: { BIcon, BIconArrowUp, BIconArrowDown },
  props: {
    id: {
      type: String,
      default: "Vue!",
    },
  },
  data: () => ({
    editAdd: false,
    dataDelete: "",
    error: "",
    dismissSecs: 2,
    dismissCountDown: 0,
    showDismissibleAlert: false,
    show: false,
    showDelete: false,
    data: {
      id: -1,
      username: "",
      name: "",
      lastnameFather: "",
      lastnameMother: "",
      email: "",
      dni: "",
      dateBirth: "",
      state: "",
    },

    estados: [
      { value: false, name: "Activo" },
      { value: true, name: "Inactivo" },
    ],
    rulesCampo: [(v) => !!v || "Campo requerido"],
    page: {
      title: "Categorias",
    },
    breadcrumbs: [
      {
        text: "Categorías",
        disabled: false,
        to: "#",
      },
      {
        text: "Todos las Categorías",
        disabled: true,
      },
    ],
    fields: [
      { key: "username", label: "Usuario" },
      { key: "name", label: "Nombres" },
      { key: "lastnameFather", label: "Apellido paterno" },
      { key: "lastnameMother", label: "Apellido materno" },
      { key: "state", label: "Estado" },
      { key: "courses", label: "Cursos" },
      { key: "actions", label: "Acciones" },
    ],
    items: [
      { age: 40, first_name: "Dickerson", last_name: "Macdonald" },
      { age: 21, first_name: "Larsen", last_name: "Shaw" },
      { age: 89, first_name: "Geneva", last_name: "Wilson" },
      { age: 38, first_name: "Jami", last_name: "Carney" },
    ],

    dialog: false,
    search: "",
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: null,
      name: "",
      props: {
        deleted: Boolean,
      },
      timestamp: "",
    },
    submitted: false,
    defaultItem: {
      id: null,
      name: "",
      props: {
        deleted: Boolean,
      },
      timestamp: "",
    },
  }),

  created() {
    this.initialize();
  },

  methods: {
    toCourses(data) {
      this.$router.push("/courses/" + data);
    },
    returnData(data) {
      if (data === "A") return "Activo";
    },
    returnColor(data) {
      if (data === "A") return "success";
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(data) {
      this.error = data;
      this.dismissCountDown = this.dismissSecs;
    },
    exit() {
      this.show = false;
    },
    exitDelete() {
      this.showDelete = false;
      this.data.username = "";
    },
    modalDelete(data) {
      this.showDelete = true;
      this.dataDelete = data;
      this.data.username = data.username;
    },
    async initialize() {
      let res = await this.$axios
        .get("/user", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
      var aux = [];
      for (var i in res.data) {
        var obj = {
          username: res.data[i].username,
          email: res.data[i].email,
          dni: res.data[i].dni,
          dateBirth: res.data[i].dateBirth,
          state: res.data[i].state,
          name: res.data[i].name,
          lastnameFather: res.data[i].lastnameFather,
          lastnameMother: res.data[i].lastnameMother,
          id: res.data[i].id,
        };
        aux.push(obj);
      }
      this.desserts = aux;
    },
    async addProgram() {
      this.editAdd = false;
      this.show = true;
    },
    async dateItem(item) {
      this.editAdd = true;
      this.show = true;
      let res = await this.$axios.get("user/editUser/" + item.id, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      });
      this.data.id = res.data.id;
      this.data.username = res.data.username;
      this.data.name = res.data.name;
      this.data.lastnameFather = res.data.lastnameFather;
      this.data.lastnameMother = res.data.lastnameMother;
      this.data.email = res.data.email;
      this.data.dni = res.data.dni;
      this.data.dateBirth = res.data.dateBirth;
      this.data.state = res.data.state;
    },
    async saveAddItem(item) {},
    async saveEditItem(item) {
      if (item.id != -1) {
        try {
          await this.$axios
            .post("/user/editUser", this.data, {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            })
            .then((res) => {
              this.$message.success(res.data.content);
              this.show = false;
              this.initialize();
            })
            .catch((e) => {
              this.showAlert(e.response.data.content);
            });
        } catch (e) {
          console.log(e);
        }
      }
    },
    async deleteItem(item) {
      this.showDelete = false;
      if (item != "") {
        try {
          await this.$axios
            .get("/user/deleteUser/" + item.id + "/", {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            })
            .then((res) => {
              this.$message.success(res.data.content);
              this.initialize();
            });
        } catch (e) {
          this.$message.danger("Error!");
          console.log(e);
        }
      }
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>